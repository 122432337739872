<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  modelValue?: number | string,
  id: string,
  placeholder: string,
  autofocus?: boolean,
  hasError?: boolean,
  hasInfo?: boolean,
  type?: 'text' | 'number' | 'email' | 'tel',
}>(), { type: 'text' })

const emit = defineEmits(['update:modelValue', 'input'])

const isNumeric = computed(() => props.type === 'number')

const sanitizeInput = (event: Event) => {
  if (!isNumeric.value) {
    return
  }
  const target = event.target as HTMLInputElement
  target.value = target.value.replace(/[^0-9.]/g, '')
}

const getValue = (event: Event): string | number => {
  const target = event.target as HTMLInputElement;
  if (!target) {
    return '';
  }

  // Return the raw string value for non-numeric inputs
  if (!isNumeric.value) {
    return target.value;
  }

  // Convert to number for numeric inputs
  const numericValue = parseFloat(target.value);
  return isNaN(numericValue) ? 0 : numericValue;
};

const emitInput = (event: Event) => {
  sanitizeInput(event)
  const value = getValue(event)
  emit('update:modelValue', value)
  emit('input', value)
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <label v-if="$slots.label" :for="id" class="flex w-full pl-2 text-sm font-bold text-slate-600" :class="{
      'text-red-400': hasError,
      'text-slate-400': !hasError && hasInfo,
    }">
      <slot name="label" />
    </label>
    <div>
      <div class="flex h-10 w-full">
        <input :id="id" :value="modelValue" :type="type" :min="isNumeric ? 1 : undefined" :name="id"
          class="focus:ring-none outline-0 block flex-1 rounded-[30px] border-0 bg-slate-200 px-4 text-gray-900 placeholder:text-gray-500 sm:text-sm"
          :placeholder="placeholder" :autofocus="autofocus" @input="emitInput" />
      </div>
    </div>
    <label v-if="hasError" :for="id" class="flex w-full pl-2 text-xs font-bold text-red-400">
      <slot name="error" />
    </label>
    <label v-if="!hasError && hasInfo" :for="id" class="flex w-full pl-2 text-xs font-bold text-slate-400">
      <slot name="info" />
    </label>
  </div>
</template>
